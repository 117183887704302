import { logAnalyticsEvent } from './src/utils/firebase/config';

export const onRouteUpdate = ({ location }) => {
  logAnalyticsEvent('page_view', {
    page_path: location.pathname,
  });
};

export const onServiceWorkerUpdateReady = () => window.location.reload(true)

export { wrapRootElement } from './gatsby-ssr';
